import { render, staticRenderFns } from "./mainLeft.vue?vue&type=template&id=6a13e976&scoped=true"
import script from "./mainLeft.vue?vue&type=script&lang=js"
export * from "./mainLeft.vue?vue&type=script&lang=js"
import style0 from "./mainLeft.vue?vue&type=style&index=0&id=6a13e976&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a13e976",
  null
  
)

export default component.exports