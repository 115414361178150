import axios from "axios";
import { Message } from "element-ui";
import routers from "../router";
import { removeToken } from "./auth";
import { showMessage } from "./util";
var repeat = true;
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000, // request timeout
});
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    // console.log(config)
    if (config.url == "/admin") {
      config.headers["Authorization"] = "admin_yudata_login";
    }
    // config.headers.accept = 'application/json,text/plain,text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9'
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    if (response.data.code === 200 || response.data.status != "error") {
      return Promise.resolve(response.data);
    } else {
      showMessage(response.data.message, "error");
      return Promise.reject(response.data);
    }
  },
  (error) => {
    if (
      error.response.request.status === 403 ||
      error.response.request.status === 401 ||
      error.response.request.status === 407
    ) {
      removeToken();
      routers.push("/login");
      if (error.response.request.status === 401) {
        showMessage("登陆信息失效", "error");
        return Promise.reject(error);
      }
    }
    showMessage(error.response.data.message ?? error, "error");
    return Promise.reject(error);
  }
);

export default service;
